import { Icon } from "@iconify/react";
import "./IconWithLabel.scss";

interface Props {
  icon: string;
  label: string;
}

const IconWithLabel: React.FC<Props> = ({ icon, label }) => {
  return (
    <div className="grid_square">
      <div className={"icon_with_label"}>
        <Icon className="icon_with_label_icon" icon={icon} />
        <label>{label}</label>
      </div>
    </div>
  );
};

export default IconWithLabel;
