import React from 'react'
import { Link } from 'react-router-dom'
import Logo from '../../images/footer-logo.png'


const Footer = (props) => {

    const EmailButton = () => {

        const email = 'contact@melymaid.com';
        const subject = 'Mely Maid Service Inquiry';
        const body = 'Hello! I would like to inquire about';

        const href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
        return (
            <a
                className='text_phone_number'
                href={href}>contact@melymaid.com</a>
        );
    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    const SubmitHandler = async (e) => {
        e.preventDefault();

        // Access the form elements through e.target
        const email = e.target.elements[0].value; // Accessing the first input element in the form

        const formData = {
            email: email
        };

        console.log(formData);


        const response = await fetch(`https://ly56qze9l6.execute-api.us-west-2.amazonaws.com/prod/newsletterSignUp`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData)
        });

        if (response.ok) {
            alert('Success! You are now signed up for our newsletter!');

            console.log(response.body);
        } else {
            alert('Failed to submit the form. Please try again.');
        }

    };

    return (
        <footer className="site-footer">
            <div className="upper-footer">
                <div className="container">
                    <div className="row">
                        <div className="col col-xl-3 col-lg-3 col-md-6 col-12">
                            <div className="widget about-widget">
                                <div className="logo widget-title">
                                    {/* <img src={Logo} alt="" /> */}
                                    <h2>Mely Maid Service</h2>
                                </div>
                                <p>Simply the best Home and Office cleaners in Orange County!</p>
                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-3 col-md-6 col-12">
                            <div className="widget link-widget">
                                <div className="widget-title">
                                    <h3>Useful Links</h3>
                                </div>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Contact us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/services">Our services</Link></li>
                                    <li><Link onClick={ClickHandler} to="/book-now">Book Now</Link></li>
                                </ul>
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                    <li><Link onClick={ClickHandler} to="/pricing">Custom Quote</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Special Offers</Link></li>
                                </ul>

                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-3 col-md-6 col-12">
                            <div className="widget contact-widget service-link-widget">
                                <div className="widget-title">
                                    <h3>Contact Info</h3>
                                </div>
                                <ul>
                                    <li>
                                        <span>Phone: </span>
                                        <a
                                            className='text_phone_number'
                                            href="tel:+19496499250">949-649-9250</a>
                                    </li>
                                    <li>
                                        <span>Email: </span>
                                        <EmailButton />
                                    </li>
                                    <li><span>Office Hours:</span> 8:00AM - 5:00PM PST</li>
                                </ul>
                            </div>
                        </div>
                        <div className="col col-xl-3 col-lg-3 col-md-6 col-12">
                            <div className="widget newsletter-widget">
                                <div className="widget-title">
                                    <h3>Newsletter</h3>
                                </div>
                                <p>Sign up for exclusive offers!</p>
                                <form onSubmit={SubmitHandler}>
                                    <div className="input-1">
                                        <input type="email" className="form-control" placeholder="Email Address *" required />
                                    </div>
                                    <div className="submit clearfix">
                                        <button type="submit"><i className="ti-email"></i></button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="lower-footer">
                <div className="container">
                    <div className="row">
                        <div className="separator"></div>
                        <div className="col col-xs-12">
                            <p className="copyright">Copyright &copy; 2024 Mely Maid. All rights reserved.</p>
                            <div className="extra-link">
                                <ul>
                                    <li><Link onClick={ClickHandler} to="/blog">News</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQ</Link></li>
                                    <li><Link onClick={ClickHandler} to="/contact">Privace & Policy</Link></li>
                                    <li><Link onClick={ClickHandler} to="/about">About us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;