import './ZipCode_Input.scss'

import React, { useEffect, useRef } from 'react';

interface Props {
  input: string;
  setInput: (input: string) => void;
}

const ZipCode_Input: React.FC<Props> = ({
  input,
  setInput,
}) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (/^\d{0,5}$/.test(newValue)) {
      setInput(newValue);
    }
  };


  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input element when the component mounts
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);



  return (
    <input
    ref={inputRef}
    className='zip_code_input'
      type="text"
      value={input}
      onChange={handleChange}
      placeholder="92627"
      autoComplete='on'
      name="zip"
    />
  );
};

export default ZipCode_Input;