import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import { useParams } from 'react-router-dom'
import Footer from '../../components/footer/Footer';
import { Link } from 'react-router-dom'
import Services from '../../api/service'
import ServiceTab from './ServiceTab';
import bImg from '../../images/service-single/benefit-pic.jpg'
import CtaSection from '../../components/CtaSection/CtaSection';


const ClickHandler = () => {
    window.scrollTo(10, 0);
}

const ServiceSinglePage = (props) => {

    const { slug } = useParams()

    const ServiceDetails = Services.find(item => item.slug === slug)

    return (
        <Fragment>
            <Navbar hclass={'header-style-1'} />
            <PageTitle pageTitle={ServiceDetails.sTitle} />

            <section className="service-single-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-lg-9 order-lg-2 order-1">
                            <div className="service-single-content">
                                <div className="service-single-img">
                                    <img src={ServiceDetails.largeImg} alt="" />
                                </div>
                                <h2>{ServiceDetails.sTitle}</h2>
                                <p>{ServiceDetails.des2}</p>
                                <blockquote>
                                    <p>I highly recommend Mely Maid Cleaning Service. Mely Maid has cleaned my home for over 20 years and are the most efficient and innovative cleaning service I have ever seen. They are punctual, courteous, reliable, dedicated and very efficient. Everyone is enthusiastic and energetic and perform their tasks with a smile. The company is sincere and honest and I trust them completely. I feel very fortunate to have found Marco and his company so many years ago. I strongly urge you to consider Mely Maid as your next housekeeping service.</p>
                                    <span>- Christine M. Fabiani, Knots of Love executive Director</span>
                                    
                                </blockquote>
                                <div className="benefit clearfix">
                                    <div className="img-holder">
                                        <img src={bImg} alt="" />
                                    </div>
                                    <div className="details">
                                        <h3>Services We Offer</h3>
                                        <ul>
                                            <li><span>1</span> General Cleaning </li>
                                            <li><span>2</span> Deep Cleaning </li>
                                            <li><span>3</span> Custom Cleaning </li>
                                        </ul>
                                        <p>At Mely Maid Service, we offer a range of cleaning options to suit your specific needs. Choose from our general cleaning for routine maintenance, deep cleaning for a thorough overhaul, or custom cleaning services tailored exactly to your requirements. Whatever your preference, we ensure a spotless, inviting space every time.</p>
                                    </div>
                                </div>
                                <h3>Pricing</h3>
                                <p>Our pricing is flexible, designed to accommodate the specific size of your home and the services you require. Regular customers who book weekly or monthly cleanings enjoy additional discounts, making our professional services even more affordable. Contact us today for a personalized quote that ensures you pay only for what you need, nothing more.</p>
                                <ServiceTab />
                                <Link onClick={ClickHandler} to="/book-now" className="theme-btn">Book a Service</Link>
                            </div>
                        </div>
                        <div className="col col-lg-3 order-lg-1 order-2">
                            <div className="service-sidebar">
                                <div className="widget service-list-widget">
                                    <ul>
                                        <li className="current"><Link onClick={ClickHandler} to="/services">Services Overview</Link></li>
                                        {Services.map((service, srv) => (
                                            <li key={srv}><Link 
                                            className={ServiceDetails.Id === service.Id ? "service-link-active" : "service-link-inactive"}
                                            onClick={ClickHandler} to={`/service-single/${service.slug}`}>{service.sTitle}</Link></li>
                                        ))}
                                    </ul>
                                </div>
                                
                                <div className="widget contact-widget">
                                    <div>
                                        <h5>Interested? <span>We've got you covered!</span></h5>
                                        <Link onClick={ClickHandler} to="/book-now">Book Now</Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <CtaSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default ServiceSinglePage;
