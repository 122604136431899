/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import "../PriceCalculatorWidget.scss";
import { useAppDispatch } from "../../../redux-slices/hooks";
import ZipCode_Input from "../../input-components/zip-code-input/ZipCode_Input";
import { setBookingContactInfo_Zip } from "../../../redux-slices/price-assistant/PriceAssistantSlice";

interface Props {}

const PriceCalc_Body_10: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const [input, setInput] = useState("");


  useEffect(() => {
    dispatch(setBookingContactInfo_Zip(input));
  }, [input]);


  return (
    <div className="price_calc_body">
      <label>What is your 5-digit postal code?</label>
      <ZipCode_Input
        input={input}
        setInput={setInput}
      />
    </div>
  );
};

export default PriceCalc_Body_10;
