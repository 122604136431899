import { useEffect, useRef } from 'react';
import './Email_Input.scss'

interface Props{
  input: string;
  setInput: (string)=>void;
  autoFocus?: boolean
}

const Email_Input: React.FC<Props> = ({
  input,
  setInput,
  autoFocus = true
}) => {

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };


  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input element when the component mounts
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);



  return(
    <div className={"email_input"} >
      <input 
      ref={inputRef}
      type="email" 
      value={input}
      onChange={handleChange}
      placeholder={"example@gmail.com"}
      autoComplete='on'
      name="email"
      />
      
    </div>
  )
};

export default Email_Input;
