import "../PriceCalculatorWidget.scss";

interface Props{

}

const PriceCalc_Body_5: React.FC<Props> = ({}) => {

  return(
    <div className={"price_calc_body"} >
        <label>Just a moment! We're finalizing your custom cleaning quote</label>
        <label> Thank you for your patience!</label>
    </div>
  )
};

export default PriceCalc_Body_5;
