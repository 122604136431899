import "../PriceCalculatorWidget.scss";

interface Props{

}

const PriceCalc_Body_1: React.FC<Props> = ({}) => {

  return(
    <div className={"price_calc_body"} >
        <label>Are you looking for Home or Office cleaning?</label>
    </div>
  )
};

export default PriceCalc_Body_1;
