import React, { Fragment, useEffect } from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import PriceCalculatorWidget from '../../components/price-calculator-widget/PriceCalculatorWidget';

const BookNowPage = () => {

    return (
        <Fragment>
            <Navbar hclass={'header-style-1'} />
            <PageTitle pageTitle={'Booking Assistant'} />
            <PriceCalculatorWidget variant='Booking'/>
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default BookNowPage;

