/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import Phone_Input from "../../input-components/phone-input/Phone_Input";
import "../PriceCalculatorWidget.scss";
import { useAppDispatch } from "../../../redux-slices/hooks";
import { setBookingContactInfo_Phone } from "../../../redux-slices/price-assistant/PriceAssistantSlice";

interface Props {}

const PriceCalc_Body_9: React.FC<Props> = ({}) => {

  const dispatch = useAppDispatch()
  const [input, setInput] = useState("")

  useEffect(()=>{
    dispatch(setBookingContactInfo_Phone(input))
  }, [input])


  return (
    <div className="price_calc_body">
      <label>What is your phone number?</label>
      <Phone_Input 
      phoneNumber={input}
      setPhoneNumber={setInput}
      />
    </div>
  );
};

export default PriceCalc_Body_9;
