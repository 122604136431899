// store.ts (cleaned up version)
import { configureStore, combineReducers } from '@reduxjs/toolkit';
import userDataReducer from './user-data/UserDataSlice';
import priceAssistantReducer from './price-assistant/PriceAssistantSlice';

// Combine reducers
const rootReducer = combineReducers({
  userData: userDataReducer,
  priceAssistant: priceAssistantReducer,
});

// Configure store without persistence
export function makeStore() {
  return configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        thunk: true,
        immutableCheck: false,
        serializableCheck: false,
      }),
    devTools: process.env.NODE_ENV !== 'production',
  });
}

const store = makeStore();

export type AppState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
