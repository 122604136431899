import React, { useEffect } from 'react';

const EmailPageContent = () => {
    // Automatic redirect on component mount
    useEffect(() => {
        const timer = setTimeout(() => {
            window.location.href = 'https://melymaid.awsapps.com/mail';
        }, 100); // Redirects after 3 seconds to give the user a moment to read the message if necessary
        
        return () => clearTimeout(timer); // Cleanup the timer if the component unmounts
    }, []);

    const clickHandler = (e) => {
        e.preventDefault();
        window.scrollTo(10, 0);
        window.location.href = 'https://melymaid.awsapps.com/mail';
    }

    return (
        <div className="contact-page-wrap">
            <section className="contact-section contact-section-s2 section-padding">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col col-xl-6  col-lg-8 col-md-10 col-12">
                            <div className="section-title">
                                <span>Access your email below</span>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col col-lg-8">
                            <div className="content-area">
                                <p>Employees can login to their email inbox below. Email us at contact@melymaid.com if you have any questions!</p>
                                <div className="email-access">
                                    <a href="https://melymaid.awsapps.com/mail" onClick={clickHandler} className="theme-btn-s2">Click here if you are not redirected automatically</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}

export default EmailPageContent;
