import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import EmailPageContent from '../../components/emailPageContent/EmailPageContent';

const EmailPage =() => {
    return(
        <Fragment>
           <Navbar hclass={'header-style-1'}/>
            <PageTitle pageTitle={'Mely Maid Email'}/> 
            <EmailPageContent/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default EmailPage;

