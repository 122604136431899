/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch, useAppSelector } from "../../../redux-slices/hooks";
import "../PriceCalculatorWidget.scss";
import PriceCalc_RectangleButton from "./PriceCalc_RectangleButton";
import {
  priceAssisstant_NextPage,
  priceAssisstant_PreviousPage,
  selectBookingContactInfo,
  selectCleaningService,
  selectNumberBathrooms,
  selectNumberBedrooms,
  selectPriceEstimate,
  selectServiceAddOns,
  selectServiceFrequency,
} from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import { BookingSubmissionType } from "../../../redux-slices/price-assistant/BookingSubmissionDataType";
import { useEffect, useState } from "react";

interface Props {}

const PriceCalc_Footer_11: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const bookingContactInfo = useAppSelector(selectBookingContactInfo);
  const cleaningService = useAppSelector(selectCleaningService);
  const numberBedrooms = useAppSelector(selectNumberBedrooms);
  const numberBathrooms = useAppSelector(selectNumberBathrooms);
  const serviceFrequency = useAppSelector(selectServiceFrequency);
  const serviceAddOns = useAppSelector(selectServiceAddOns);
  const priceEstimate = useAppSelector(selectPriceEstimate);

  const [submitting, setSubmitting] = useState(false);

  const prevPage = () => {
    dispatch(priceAssisstant_PreviousPage());
  };

  const submitHandler = async () => {
    setSubmitting(true);
    const formData: BookingSubmissionType = {
      fullName: bookingContactInfo.fullName,
      email: bookingContactInfo.email,
      phoneNumber: bookingContactInfo.phoneNumber,
      street: bookingContactInfo.street,
      city: bookingContactInfo.city,
      state: bookingContactInfo.state,
      zip: bookingContactInfo.zip,
      comments: bookingContactInfo.comments,
      cleaningService: cleaningService,
      numberBedrooms: numberBedrooms,
      numberBathrooms: numberBathrooms,
      serviceFrequency: serviceFrequency,
      deepCleanAddOnEnabled: serviceAddOns.deepClean,
      windowCleaningAddOnEnabled: serviceAddOns.windowCleaning,
      priceEstimate: priceEstimate,
    };

    const response = await fetch(
      `https://2jks0z3t5c.execute-api.us-west-2.amazonaws.com/default/submitBookingRequest`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      }
    );

    if (response.ok) {
      dispatch(priceAssisstant_NextPage());
    } else {
      alert("Failed to submit the form. Please try again.");
    }
    setSubmitting(false);
  };


  const [loadingText, setLoadingText] = useState('Submitting');

  useEffect(() => {
    if (submitting) {
      const loadingIntervals = ['Submitting', 'Submitting .', 'Submitting ..', 'Submitting ...'];
      let currentIndex = 0;
      
      const interval = setInterval(() => {
        currentIndex = (currentIndex + 1) % loadingIntervals.length;
        setLoadingText(loadingIntervals[currentIndex]);
      }, 500);
      
      return () => clearInterval(interval);
    } else {
      setLoadingText('Submitting');
    }
  }, [submitting]);

  return (
    <div className="price_calc_footer_final">
      {
        submitting &&
        <div className="price_calc_footer_final_loading">
      {loadingText}
      </div>
      }
      

      <PriceCalc_RectangleButton
        enabled={!submitting}
        label="Back"
        handleClick={prevPage}
      />
      <PriceCalc_RectangleButton
        enabled={!submitting}
        type="submit"
        label="Submit"
        handleClick={() => {
          submitHandler();
        }}
      />
    </div>
  );
};

export default PriceCalc_Footer_11;
