import React from 'react'
import abimg from '../../images/orange-county-logo.jpg'
import abimg2 from '../../images/about-2.jpg'


const About2 = (props) => {
    return (
        <section className="about-section-s2 section-padding">
            <div className="container">
                <div className="row">
                    <div className="col col-xs-12">
                        <div className="about-row clearfix">
                            <div className="img-holder">
                                <img src={abimg} alt="" />
                            </div>
                            <div className="details">
                                <div className="middle">
                                    <h3>Serving Orange County Since 2002!</h3>
                                    <p>
                                        Welcome to Mely Maid Service, where our 20-plus years of expertise ensure your home transforms into a haven of cleanliness and comfort. We cater to the diverse needs of professionals, families, and homeowners alike, personalizing our cleaning to suit your specific preferences. With a strong reputation built on reliability and customer satisfaction, our seasoned team guarantees efficiency and impeccable results with every visit. Contact us today to experience the difference decades of dedication make and let us revitalize your living space!
                                    </p>

                                </div>
                            </div>
                        </div>
                        <div className="about-row about-row-2 clearfix">
                            <div className="details">
                                <div className="middle">
                                    <h3>Available Service Locations</h3>
<p>We currently operate in the following locations:</p>
                                    <div className='service-locations'>
                                        <ul>
                                            <li>Costa Mesa</li>
                                            <li>Long Beach</li>
                                            <li>Huntington Beach</li>
                                            <li>Irvine</li>
                                            <li>Santa Ana</li>
                                            <li>Garden Grove</li>
                                            <li>Fountain Valley</li>
                                            <li> Newport Beach</li>
                                        </ul>

                                        <ul>
                                            <li>Laguna Beach</li>
                                            <li>Lake Forest</li>
                                            <li>Anaheim</li>
                                            <li>Aliso Viejo</li>
                                            <li>Mission Viejo</li>
                                            <li>Dana Point</li>
                                            <li>Tustin</li>
                                            <li>Ladera Ranch</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="img-holder">
                                <img src={abimg2} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About2;