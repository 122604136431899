import React, { useState } from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Row, Col } from 'reactstrap';
import classnames from 'classnames';


const ServiceTab = (props) => {
    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }

    return (
        <div className="service-single-tab">
            <ul className="nav">
                <Nav tabs>
                    <NavItem >
                        <NavLink
                            className={activeTab === '1' && "active-tab"}
                            onClick={() => { toggle('1'); }}
                        >
                            General Cleaning
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === '2' && "active-tab"}
                            onClick={() => { toggle('2'); }}
                        >

                            Deep Clean
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            className={activeTab === '3' && "active-tab"}
                            onClick={() => { toggle('3'); }}
                        >

                            Custom Cleaning Services
                        </NavLink>
                    </NavItem>
                </Nav>
            </ul>

            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <Row>
                        <Col sm="12">
                            <p>Our general cleaning service at Mely Maid Service covers every corner of your home to ensure a comprehensive clean. In all rooms, we dust and polish furniture, clean window ledges, remove cobwebs, vacuum, clean mirrors, and change bed sheets. Our bathroom cleaning includes sanitizing sinks, toilets, mirrors, and the exteriors of cabinets, plus scrubbing showers thoroughly. In the kitchen, we clean sinks, exteriors of appliances, including ovens and refrigerators, the inside and outside of microwaves, as well as wiping down counters, tables, and chairs, and washing the floors. Throughout your home, we also sweep and mop the floors, leaving your space spotless and refreshed.</p>
                        </Col>
                    </Row>
                </TabPane>
                <TabPane tabId="2">
                    <p>Our deep cleaning service at Mely Maid Service extends beyond our thorough general cleaning to provide an even more intensive clean. It includes everything in our general service—dusting and polishing furniture, cleaning window ledges, removing cobwebs, vacuuming, cleaning mirrors, and changing bed sheets. Bathrooms are meticulously sanitized, and showers are scrubbed clean. In addition, deep cleaning tackles the inside and outside of refrigerators and ovens, and cleans all cabinet interiors and exteriors. In the kitchen, we also take care of sinks, appliances, counters, tables, chairs, and floors. With our deep cleaning, every nook and cranny of your home receives extra attention for the ultimate in cleanliness.</p>
                </TabPane>
                <TabPane tabId="3">
                    <p>Our custom cleaning service at Mely Maid Service is designed to meet your unique needs perfectly. It includes all the essentials of our general cleaning, such as dusting, vacuuming, and mirror cleaning, and allows you to add any specific services you require. Whether it's a thorough clean inside your refrigerator and oven, detailed attention to all cabinet interiors and exteriors, or any other specific area of your home, we tailor our cleaning to suit your specific requests. Let us know what you need, and we'll create a personalized cleaning plan that fits your home and lifestyle perfectly.</p>
                </TabPane>
            </TabContent>
        </div>
    );
}

export default ServiceTab;