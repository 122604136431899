import React, { Fragment, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/List";
import Collapse from "@mui/material/Collapse";
import { Link, useLocation } from "react-router-dom";
import "./MobileMenu.scss";

const menus = [
  {
    id: 1,
    title: "Home",
    link: "/",
  },
  {
    id: 31,
    title: "About",
    link: "/about",
    submenu: [
      {
        id: 31,
        title: "About Us",
        link: "/about",
      },
      {
        id: 322,
        title: "Testimonials",
        link: "/testimonials",
      },
      {
        id: 36,
        title: "FAQ",
        link: "/faq",
      },
    ],
  },
  {
    id: 6,
    title: "Services",
    link: "/services",
    submenu: [
      {
        id: 61,
        title: "Our Services",
        link: "/services",
      },
      {
        id: 62,
        title: "Home Cleaning",
        link: "/service-single/Home-Cleaning",
      },
      {
        id: 63,
        title: "Office Cleaning",
        link: "/service-single/Office-Cleaning",
      },
      {
        id: 64,
        title: "Window Cleaning",
        link: "/service-single/Window-Cleaning",
      },
      {
        id: 64,
        title: "Move-In / Move-Out Cleaning",
        link: "/service-single/move-in-cleaning",
      },
    ],
  },
  {
    id: 7,
    title: "Pricing",
    link: "/pricing",
  },
  {
    id: 88,
    title: "Contact",
    link: "/contact",
  },
  {
    id: 99,
    title: "Book Now",
    link: "/book-now",
  },
];

const MobileMenu = () => {
  const [openId, setOpenId] = useState(0);
  const [menuActive, setMenuState] = useState(false);
  const location = useLocation();

  const ClickHandler = (link) => {
    setMenuState(false);
    setOpenId(0);
    if (location.pathname === link) {
      window.location.reload();
    } else {
      window.scrollTo(10, 0);
    }
  };

  return (
    <div>
      <div className={`mobileMenu ${menuActive ? "show" : ""}`}>
        <div className="menu-close">
          <div className="clox" onClick={() => setMenuState(!menuActive)}>
            <i className="ti-close"></i>
          </div>
        </div>

        <ul className="responsivemenu">
          {menus.map((item, mn) => {
            return (
              <ListItem
                className={item.id === openId ? "active" : null}
                key={mn}
              >
                {item.submenu ? (
                  <Fragment>
                    <p
                      onClick={() =>
                        setOpenId(item.id === openId ? 0 : item.id)
                      }
                    >
                      {item.title}
                      <i
                        className={
                          item.id === openId ? "ti-angle-up" : "ti-angle-down"
                        }
                      ></i>
                    </p>
                    <Collapse
                      in={item.id === openId}
                      timeout="auto"
                      unmountOnExit
                    >
                      <List className="subMenu">
                        <Fragment>
                          {item.submenu.map((submenu, i) => {
                            return (
                              <ListItem key={i}>
                                <Link
                                  onClick={() => ClickHandler(submenu.link)}
                                  className="active"
                                  to={submenu.link}
                                >
                                  {submenu.title}
                                </Link>
                              </ListItem>
                            );
                          })}
                        </Fragment>
                      </List>
                    </Collapse>
                  </Fragment>
                ) : (
                  <Link
                    className="active"
                    onClick={() => ClickHandler(item.link)}
                    to={item.link}
                  >
                    {item.title}
                  </Link>
                )}
              </ListItem>
            );
          })}
        </ul>
      </div>

      <div className="showmenu" onClick={() => setMenuState(!menuActive)}>
        <button type="button" className="navbar-toggler open-btn">
          <span className="icon-bar first-angle"></span>
          <span className="icon-bar middle-angle"></span>
          <span className="icon-bar last-angle"></span>
        </button>
      </div>
    </div>
  );
};

export default MobileMenu;
