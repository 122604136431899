/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch } from "../../../redux-slices/hooks";
import {
  priceAssisstant_NextPage,
  setNumberBedrooms,
} from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";
import PriceCalc_SquareButton from "./PriceCalc_SquareButton";

interface Props {}

const PriceCalc_Footer_2: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();

  const handleButtonClick = (numberBedrooms: number) => {
    dispatch(priceAssisstant_NextPage());
    dispatch(setNumberBedrooms(numberBedrooms));
  };

  return (
    <div className="price_calc_footer_grid_6">
      {[1, 2, 3, 4, 5, 6].map((i) => (
        <div key={i} className="price_calc_footer_grid_square">
          <PriceCalc_SquareButton
            label={`${i}`}
            handleClick={() => {
              handleButtonClick(i);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default PriceCalc_Footer_2;
