/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import "../PriceCalculatorWidget.scss";
import { PriceCalculatorDictionary } from "../PriceCalculatorDictionary";
import PriceCalc_Footer_1 from "./PriceCalc_Footer_1";

interface Props {
  pageNumber: number;
}

const PriceCalc_Footer: React.FC<Props> = ({ pageNumber = 0 }) => {
  const [footerComponent, setFooterComponent] = useState(
    <PriceCalc_Footer_1 />
  );

  useEffect(() => {
    setFooterComponent(
      PriceCalculatorDictionary[pageNumber]?.footerComponent ||
        PriceCalculatorDictionary[0].footerComponent
    );
  }, [pageNumber]);

  return <div className="price_calc_right_footer">{footerComponent}</div>;
};

export default PriceCalc_Footer;
