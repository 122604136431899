/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
// @ts-ignore
import MelyMaidLogo from "../../images/mely-maid-logo.jpg";
import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../redux-slices/hooks";
import {
  priceAssisstant_ResetData,
  selectCurrentPriceAssistantPage,
  setAssistantVariant,
  setCurrentPriceAssistantPage,
} from "../../redux-slices/price-assistant/PriceAssistantSlice";

import "./PriceCalculatorWidget.scss";
import PriceCalc_Body from "./body-components/PriceCalc_Body";
import PriceCalc_Footer from "./footer-components/PriceCalc_Footer";
import PriceCalc_Header from "./header-components/PriceCalc_Header";
import { PriceCalculatorDictionary } from "./PriceCalculatorDictionary";


interface Props {
  variant: "Price" | "Booking";
}

const PriceCalculatorWidget: React.FC<Props> = ({ variant = "Price" }) => {
  const pageNumber = useAppSelector(selectCurrentPriceAssistantPage);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (variant === "Booking") {
      dispatch(setAssistantVariant("Booking"));
    }else{
      dispatch(setAssistantVariant("Price"));
    }
  }, [variant]);

  useEffect(() => {
    dispatch(priceAssisstant_ResetData());
  }, []);

  useEffect(() => {
    if (!(pageNumber.toString() in PriceCalculatorDictionary)) {
      dispatch(setCurrentPriceAssistantPage(0));
    }
  }, [pageNumber]);

      
  const EmailButton = () => {

    const email = 'contact@melymaid.com';
    const subject = 'Mely Maid Service Inquiry';
    const body = 'Hello! I would like to inquire about';

    const href = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    return (
        <a
            className='text_phone_number'
            href={href}>contact@melymaid.com</a>
    );
}

  return (
    <>
     <div className="price_calc_container">
      <div className="price_calc">
        <div className="price_calc_left">
          <img
            height={410}
            width={410}
            src={MelyMaidLogo}
            alt="Mely Maid Logo"
          />
        </div>

        <div className="price_calc_right">
          <PriceCalc_Header pageNumber={pageNumber} />
          <PriceCalc_Body pageNumber={pageNumber} />
          <PriceCalc_Footer pageNumber={pageNumber} />
        </div>
      </div>
    </div>
    
    <section className="contact-info-section section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col col-xs-12">
                            <div className="contact-info clearfix">
                                <div>
                                    <div className="icon">
                                        <i className="ti-email"></i>
                                    </div>
                                    <h5>Email</h5>
                                    <EmailButton/>
                                </div>
                                <div>
                                    <div className="icon">
                                        <i className="ti-headphone-alt"></i>
                                    </div>
                                    <h5>Phone</h5>
                                    <a 
                                className='text_phone_number'
                                href="tel:+19496499250">949-649-9250</a>
                                </div>
                                <div>
                                    <div className="icon">
                                        <i className="ti-location-pin"></i>
                                    </div>
                                    <h5>Location</h5>
                                    <p>Orange County, California, USA</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
    </>
   
  );
};

export default PriceCalculatorWidget;
