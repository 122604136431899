/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useAppDispatch } from "../../../redux-slices/hooks";
import Email_Input from "../../input-components/email-input/Email_Input";
import "../PriceCalculatorWidget.scss";
import { setBookingContactInfo_Email } from "../../../redux-slices/price-assistant/PriceAssistantSlice";

interface Props {}

const PriceCalc_Body_8: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const [input, setInput] = useState("");

  useEffect(() => {
    dispatch(setBookingContactInfo_Email(input));
  }, [input]);

  return (
    <div className="price_calc_body">
      <label>What is your email address?</label>
      <Email_Input input={input} setInput={setInput} />
    </div>
  );
};

export default PriceCalc_Body_8;
