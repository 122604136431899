import React, { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';


const RequestCallBackForm = () => {

    const [forms, setForms] = useState({
        name: '',
        service: 'Home Cleaning',
        phone: '',
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
    
        if (validator.allValid()) {
            const formData = {
                name: forms.name,
                service: forms.service,
                phone: forms.phone,
            };
        
            console.log(formData);
            const response = await fetch(`https://enmlni1aj6.execute-api.us-west-2.amazonaws.com/default/requestCallBackSubmission`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
                
            if (response.ok) {
                alert('Form submitted successfully!');
                validator.hideMessages();
                setForms({
                    name: '',
                    service: 'Home Cleaning',
                    phone: '',
                });  // Clearing the form after successful submission
                console.log(response.body);
            } else {
                alert('Failed to submit the form. Please try again.');
            }
        } else {
            validator.showMessages();
        }
    };
    

    return (
        <div className="contact-form">
            <h3>Request Call Back!</h3>
            <p>Interested in our cleaning services? Drop your number, and we'll get back to you shortly!</p>
            <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
                <div className="form-field">
                    <input
                        value={forms.name}
                        type="text"
                        name="name"
                        className="form-control"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        placeholder="Your Name" />
                    {validator.message('name', forms.name, 'required|alpha_space')}
                </div>
                <div className="form-field">
                    <input
                        value={forms.phone}
                        type="phone"
                        name="phone"
                        className="form-control"
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        placeholder="Your phone" />
                    {validator.message('phone', forms.phone, 'required|phone')}
                </div>
                <div className="form-field">
                    <select
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.service}
                        type="text"
                        className="form-control"
                        name="service">
                        <option>Home Cleaning</option>
                        <option>Office Cleaning</option>
                        <option>Window Cleaning</option>
                        <option>Move-in / Move-out Cleaning</option>
                    </select>
                    {validator.message('service', forms.service, 'string')}
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="submit-area">
                        <button type="submit" className="theme-btn">Submit Now</button>
                    </div>
                </div>
            </form>
        </div >
    )
}

export default RequestCallBackForm;