import "./Phone_Input.scss";
import React, { useEffect, useRef, useState } from "react";
import InputMask from "react-input-mask";

interface Props {
  phoneNumber: string;
  setPhoneNumber: (input: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

const Phone_Input: React.FC<Props> = ({
  phoneNumber,
  setPhoneNumber,
  placeholder = "949-649-9250",
  autoFocus = true
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const input = e.target.value;
    setPhoneNumber(input);
  };


  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input element when the component mounts
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);


  return (
    <div className="phone_input">
      <InputMask
        mask="(999) 999-9999"
        value={phoneNumber}
        onChange={handleChange}
        placeholder={placeholder}
      >
        {(inputProps: any) => <input 
        {...inputProps} 
        ref={inputRef} 
        type="tel" 
        name="phone"
        autoComplete="on"
        />}
      </InputMask>
    </div>
  );
};

export default Phone_Input;
