import React, {Fragment, useEffect} from 'react';
import Navbar from '../../components/Navbar/Navbar';
import PageTitle from '../../components/pagetitle/PageTitle'
import Scrollbar from '../../components/scrollbar/scrollbar'
import Footer from '../../components/footer/Footer';
import CtaSection from '../../components/CtaSection/CtaSection';
import PriceCalculatorWidget from '../../components/price-calculator-widget/PriceCalculatorWidget';

const PricingPage =() => {


    return(
        <Fragment>
           <Navbar hclass={'header-style-1'}/>
            <PageTitle pageTitle={'Price Assistant'}/> 
            <PriceCalculatorWidget
            variant='Price'
            />    
            <CtaSection/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default PricingPage;

