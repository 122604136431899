/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import "../PriceCalculatorWidget.scss";
import { PriceCalculatorDictionary } from "../PriceCalculatorDictionary";
import PriceCalc_Body_1 from "./PriceCalc_Body_1";

interface Props {
  pageNumber: number;
}

const PriceCalc_Body: React.FC<Props> = ({ pageNumber = 0 }) => {
  const [bodyComponent, setBodyComponent] = useState(<PriceCalc_Body_1 />);

  useEffect(() => {
    setBodyComponent(
      PriceCalculatorDictionary[pageNumber]?.bodyComponent ||
        PriceCalculatorDictionary[0].bodyComponent
    );
  }, [pageNumber]);

  return <div className="price_calc_right_body">{bodyComponent}</div>;
};

export default PriceCalc_Body;
