import "../PriceCalculatorWidget.scss";

interface Props{

}

const PriceCalc_Body_2: React.FC<Props> = ({}) => {

  return(
    <div className={"price_calc_body"} >
        <label>Choose the number of bedrooms for your cleaning plan</label>
    </div>
  )
};

export default PriceCalc_Body_2;
