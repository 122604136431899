import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { AppState } from "../store";
import { CleaningServiceType } from "./CleaningServiceType";
import { ServiceFrequencyType } from "./ServiceFrequencyType";
import { ServiceAddOnsType } from "./ServiceAddOnsType";
import { BookingContactInfoType } from "./BookingContactInfoType";
import { AssistantVariantType } from "./AssistantVariantType";

export interface PriceAssistantState {
  assistantVariant: AssistantVariantType;
  currentPriceAssistantPage: number;
  cleaningService: CleaningServiceType;
  numberBedrooms: number;
  numberBathrooms: number;
  serviceFrequency: ServiceFrequencyType;
  serviceAddOns: ServiceAddOnsType;
  weeklyDiscount: number;
  biWeeklyDiscount: number;
  windowCleaningAddOnPrice: number;
  deepCleanAddOnPrice: number;
  bookingContactInfo: BookingContactInfoType;
  priceEstimate: number;
}

const initialState: PriceAssistantState = {
  assistantVariant: "Booking",
  currentPriceAssistantPage: 0,
  cleaningService: "Home Cleaning",
  numberBedrooms: 1,
  numberBathrooms: 1,
  serviceFrequency: "One-time Service",
  serviceAddOns: {
    deepClean: false,
    windowCleaning: false,
  },
  weeklyDiscount: 10,
  biWeeklyDiscount: 5,
  windowCleaningAddOnPrice: 50,
  deepCleanAddOnPrice: 50,
  bookingContactInfo: {
    fullName: "",
    email: "",
    phoneNumber: "",
    street: "",
    city: "",
    state: "",
    zip: "",
    comments: "", 
  },
  priceEstimate: 0,
  
};

export const priceAssistantSlice = createSlice({
  name: "priceAssistant",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    // Use the PayloadAction type to declare the contents of `action.payload`

    setAssistantVariant: (state, action: PayloadAction<AssistantVariantType>) => {
      state.assistantVariant = action.payload;
    },

    setCurrentPriceAssistantPage: (state, action: PayloadAction<number>) => {
      state.currentPriceAssistantPage = action.payload;
    },

    priceAssisstant_NextPage: (state) => {
      state.currentPriceAssistantPage = state.currentPriceAssistantPage + 1;
    },

    priceAssisstant_PreviousPage: (state) => {
      state.currentPriceAssistantPage = state.currentPriceAssistantPage - 1;
      if (state.currentPriceAssistantPage < 0) {
        state.currentPriceAssistantPage = 0;
      }
    },

    priceAssisstant_ResetData: (state) => {
      state.currentPriceAssistantPage = 0;
      state.cleaningService = "Home Cleaning";
      state.numberBedrooms = 0;
      state.numberBathrooms = 0;
      state.serviceFrequency = "One-time Service";
      state.serviceAddOns = {
        deepClean: false,
        windowCleaning: false,
      };
      state.bookingContactInfo = {
        fullName: "",
        email: "",
        phoneNumber: "",
        street: "",
        city: "",
        state: "",
        zip: "",
        comments: "",
      };
      state.priceEstimate = 0;
    },

    setCleaningService: (state, action: PayloadAction<CleaningServiceType>) => {
      state.cleaningService = action.payload;
    },

    setNumberBedrooms: (state, action: PayloadAction<number>) => {
      state.numberBedrooms = action.payload;
    },

    setNumberBathrooms: (state, action: PayloadAction<number>) => {
      state.numberBathrooms = action.payload;
    },

    setServiceFrequency: (
      state,
      action: PayloadAction<ServiceFrequencyType>
    ) => {
      state.serviceFrequency = action.payload;
    },

    setServiceAddOns: (state, action: PayloadAction<ServiceAddOnsType>) => {
      state.serviceAddOns = action.payload;
    },

    addWindowCleaningService: (state) => {
      state.serviceAddOns = {
        ...state.serviceAddOns,
        windowCleaning: true,
      };
    },

    addDeepCleanService: (state) => {
      state.serviceAddOns = {
        ...state.serviceAddOns,
        deepClean: true,
      };
    },

    setBookingContactInfo: (state, action: PayloadAction<BookingContactInfoType>) => {
      state.bookingContactInfo = action.payload;
    },

    setBookingContactInfo_FullName: (state, action: PayloadAction<string>) => {
      state.bookingContactInfo = {
        ...state.bookingContactInfo,
        fullName: action.payload,
      };
    },

    setBookingContactInfo_Email: (state, action: PayloadAction<string>) => {
      state.bookingContactInfo = {
        ...state.bookingContactInfo,
        email: action.payload,
      };
    },


    setBookingContactInfo_Phone: (state, action: PayloadAction<string>) => {
      state.bookingContactInfo = {
        ...state.bookingContactInfo,
        phoneNumber: action.payload,
      };
    },

    setBookingContactInfo_Street: (state, action: PayloadAction<string>) => {
      state.bookingContactInfo = {
        ...state.bookingContactInfo,
        street: action.payload,
      };
    },

    setBookingContactInfo_City: (state, action: PayloadAction<string>) => {
      state.bookingContactInfo = {
        ...state.bookingContactInfo,
        city: action.payload,
      };
    },

    setBookingContactInfo_State: (state, action: PayloadAction<string>) => {
      state.bookingContactInfo = {
        ...state.bookingContactInfo,
        state: action.payload,
      };
    },

    setBookingContactInfo_Zip: (state, action: PayloadAction<string>) => {
      state.bookingContactInfo = {
        ...state.bookingContactInfo,
        zip: action.payload,
      };
    },

    setBookingContactInfo_Comments: (state, action: PayloadAction<string>) => {
      state.bookingContactInfo = {
        ...state.bookingContactInfo,
        comments: action.payload,
      };
    },

    setPriceEstimate: (state, action: PayloadAction<number>) => {
      state.priceEstimate = action.payload
    },

  },
});

export const {
  setAssistantVariant,
  setCurrentPriceAssistantPage,
  priceAssisstant_NextPage,
  priceAssisstant_PreviousPage,
  priceAssisstant_ResetData,
  setCleaningService,
  setNumberBedrooms,
  setNumberBathrooms,
  setServiceFrequency,
  setServiceAddOns,
  addWindowCleaningService,
  addDeepCleanService,
  setBookingContactInfo,
  setBookingContactInfo_FullName,
  setBookingContactInfo_Email,
  setBookingContactInfo_Phone,
  setBookingContactInfo_Street,
  setBookingContactInfo_City,
  setBookingContactInfo_State,
  setBookingContactInfo_Zip,
  setBookingContactInfo_Comments,
  setPriceEstimate,
} = priceAssistantSlice.actions;

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`

export const selectAssistantVariant = (state: AppState) =>
  state.priceAssistant.assistantVariant;


export const selectCurrentPriceAssistantPage = (state: AppState) =>
  state.priceAssistant.currentPriceAssistantPage;

export const selectCleaningService = (state: AppState) =>
  state.priceAssistant.cleaningService;

export const selectNumberBedrooms = (state: AppState) =>
  state.priceAssistant.numberBedrooms;

export const selectNumberBathrooms = (state: AppState) =>
  state.priceAssistant.numberBathrooms;

export const selectServiceFrequency = (state: AppState) =>
  state.priceAssistant.serviceFrequency;

export const selectServiceAddOns = (state: AppState) =>
  state.priceAssistant.serviceAddOns;

export const selectWeeklyDiscount = (state: AppState) =>
  state.priceAssistant.weeklyDiscount;

export const selectBiWeeklyDiscount = (state: AppState) =>
  state.priceAssistant.biWeeklyDiscount;

export const selectWindowCleaningAddOnPrice = (state: AppState) =>
  state.priceAssistant.windowCleaningAddOnPrice;

export const selectDeepCleaningAddOnPrice = (state: AppState) =>
  state.priceAssistant.deepCleanAddOnPrice;

export const selectBookingContactInfo = (state: AppState) =>
  state.priceAssistant.bookingContactInfo;

export const selectPriceEstimate = (state: AppState) =>
  state.priceAssistant.priceEstimate;

export default priceAssistantSlice.reducer;
