import { useAppDispatch } from "../../../redux-slices/hooks";
import { priceAssisstant_NextPage, setCleaningService, setCurrentPriceAssistantPage, setNumberBathrooms, setNumberBedrooms } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";
import { Icon } from "@iconify/react";

interface Props {}

const PriceCalc_Footer_1: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();

  const handleButtonClick_HomeCleaning = () => {
    dispatch(setCleaningService("Home Cleaning"))
    dispatch(priceAssisstant_NextPage());
  };

  const handleButtonClick_OfficeCleaning = () => {
    dispatch(setCleaningService("Office Cleaning"))
    dispatch(setNumberBedrooms(0))
    dispatch(setNumberBathrooms(0))
    dispatch(setCurrentPriceAssistantPage(3));
  };

  return (
    <div className={"price_calc_footer_vertical"}>
      <button
        onClick={handleButtonClick_HomeCleaning}
        className="price_calc_footer_button_blue_rect"
      >
        <Icon
          className="price_calc_footer_button_blue_rect_icon"
          icon={"noto:house"}
        />
        Home Cleaning
      </button>
      <button
        onClick={handleButtonClick_OfficeCleaning}
        className="price_calc_footer_button_blue_rect"
      >
        <Icon
          className="price_calc_footer_button_blue_rect_icon"
          icon={"noto:office-building"}
        />
        Office Cleaning
      </button>
    </div>
  );
};

export default PriceCalc_Footer_1;
