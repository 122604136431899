import React from 'react';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import tImg1 from '../../images/testimonials/img-1.jpg'
import tImg2 from '../../images/testimonials/img-2.jpg'


const settings = {
    dots: true,
    arrows: false,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
};


const Testimonial = (props) => {

    return (
        <section className={`testimonials-section section-padding ${props.tClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-4 col-12">
                        <div className="section-title-s2">
                            <h2>Here are some of our happy clients!</h2>
                        </div>
                    </div>
                    <div className="col col-lg-8 col-12">
                        <div className="testimonial-grids testimonials-slider">
                            <Slider {...settings}>
                                <div className="grid">
                                    <div className="quote">
                                        <p>I highly recommend Mely Maid Cleaning Service. Mely Maid has cleaned my home for over 20 years and are the most efficient and innovative cleaning service I have ever seen. They are punctual, courteous, reliable, dedicated and very efficient. Everyone is enthusiastic and energetic and perform their tasks with a smile. The company is sincere and honest and I trust them completely. I feel very fortunate to have found Marco and his company so many years ago. I strongly urge you to consider Mely Maid as your next housekeeping service.</p>
                                    </div>
                                    <div className="details">
                                        <div className="img-holder">
                                            <img src={tImg1} alt="" />
                                        </div>
                                        <h5>Christine M. Fabiani</h5>
                                        <span>Knots of Love executive Director</span>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="quote">
                                        <p>The best cleaning service ever! Marco and his wonderful team have been cleaning out on a weekly basis for over 10 years. They have also cleaned our rental property. Their work is impeccable. They are always very thorough and professional and very trustworthy. Marco has stepped up on many occasions to perform extra jobs like cleaning silver, washing fine china and cleaning the inside of our China cabinets. His quality of work is exceptional and we look forward to many more years of his service.</p>
                                    </div>
                                    <div className="details">
                                        <div className="img-holder">
                                            <img src={tImg1} alt="" />
                                        </div>
                                        <h5>Laura W.</h5>
                                        <span>Happy Client</span>
                                    </div>
                                </div>
                                <div className="grid">
                                    <div className="quote">
                                        <p>Mely Maid Service is by far the best maid service I have found, I have been using them for the last 12 years, the ladies became family to me.
                                            Every time Marco and his team have been extremely prompt, friendly, and very through in their work. The price is reasonable and worth it. Everyone is extremely trustworthy and they clean and fix problems I never even knew existed.I have used several other services before and this is by far the best. I can’t say enough good things about Mely Maid Service!
                                            I would definitely refer them and recommend their service. </p>
                                    </div>
                                    <div className="details">
                                        <div className="img-holder">
                                            <img src={tImg1} alt="" />
                                        </div>
                                        <h5>Bihan A.</h5>
                                        <span>Happy Client</span>
                                    </div>
                                </div>

                                <div className="grid">
                                    <div className="quote">
                                        <p>Marco has been our family housekeeper for over 8 years now! Marco does a great job but most importantly, he and his crew are very honest and trust worthy. Marco is open minded and if some thing was not up to your standard, you can certainly share that with him and he will make sure you are happy. Through the years, he has been consistent with maintaining a neat and dust free home for us. I have referred other friends and family to Marco and they feel the same way. Thanks Marco for all that you have done and will continue on doing.</p>
                                    </div>
                                    <div className="details">
                                        <div className="img-holder">
                                            <img src={tImg1} alt="" />
                                        </div>
                                        <h5>Melissa D.</h5>
                                        <span>Happy Client</span>
                                    </div>
                                </div>

                                <div className="grid">
                                    <div className="quote">
                                        <p>Marco from Melymaids is a wonderful and awesome person.  I have never had a house keeper as good as Melymaids. When they clean my house they always have 2 people and they do a fantastic job and they get it all done in about 1 hour to 1 in a half hours.. This Melymaids is the best company I have ever had clean my house… I would definitely refer them to other prospective customers they are the best thank to Marco and Crew you always do a outstanding job thanks.</p>
                                    </div>
                                    <div className="details">
                                        <div className="img-holder">
                                            <img src={tImg1} alt="" />
                                        </div>
                                        <h5>Nick W.</h5>
                                        <span>Happy Client</span>
                                    </div>
                                </div>
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default Testimonial;