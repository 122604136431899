import "../PriceCalculatorWidget.scss";

interface Props {
  label: string;
  handleClick: () => void;
}

const PriceCalc_SquareButton: React.FC<Props> = ({ label, handleClick }) => {
  return (
    <button className="price_calc_footer_square_button" onClick={handleClick}>
      <label>{label}</label>
    </button>
  );
};

export default PriceCalc_SquareButton;
