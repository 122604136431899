import "../PriceCalculatorWidget.scss";

interface Props {}

const PriceCalc_Body_4b: React.FC<Props> = ({}) => {

  return (
    <div className={"price_calc_body"}>
      <label>Would you like to add the Window Cleaning service?</label>
      <label>(We clean the inside and outside of the glass panes, windowsills, screen doors, and tracks)</label>
    </div>
  );
};

export default PriceCalc_Body_4b;
