import './String_Input.scss'

import React, { useEffect, useRef } from 'react';

interface Props {
  input: string;
  setInput: (input: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
  inputName?:string
}

const String_Input: React.FC<Props> = ({
  input,
  setInput,
  placeholder = "Enter text",
  autoFocus = true,
  inputName = "name"
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInput(e.target.value);
  };

  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input element when the component mounts
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);



  return (
    <div className="string_input">
      <input
      ref={inputRef} 
        type="text"
        value={input}
        onChange={handleChange}
        placeholder={placeholder}
        autoComplete='on'
        name={inputName}
      />
    </div>
  );
};

export default String_Input;
