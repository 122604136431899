import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux-slices/hooks";
import { selectCleaningService } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";

interface Props {}

const PriceCalc_Body_4a: React.FC<Props> = ({}) => {
  const cleaingService = useAppSelector(selectCleaningService);
  const [serviceString, setServiceString] = useState("We clean the inside and outside of refrigerator, oven, and all cabinets");

  useEffect(() => {
    if (cleaingService === "Home Cleaning") {
      setServiceString("We clean the inside and outside of refrigerator, oven, and all cabinets");
    } else {
      setServiceString("We clean the inside and outside of refrigerator, oven, and all cabinets");
    }
  }, [cleaingService]);

  return (
    <div className={"price_calc_body"}>
      <label>Would you like to add the Deep Cleaning Service?</label>
      <label>( {serviceString} )</label>
    </div>
  );
};

export default PriceCalc_Body_4a;
