/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch, useAppSelector } from "../../../redux-slices/hooks";
import "../PriceCalculatorWidget.scss";
import PriceCalc_RectangleButton from "./PriceCalc_RectangleButton";
import { priceAssisstant_NextPage, priceAssisstant_PreviousPage, selectBookingContactInfo } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import { useEffect, useState } from "react";

interface Props {}

const PriceCalc_Footer_10: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const bookingContactInfo = useAppSelector(selectBookingContactInfo)
  const [validated, setValidated] = useState(false);

  const prevPage = () => {
    dispatch(priceAssisstant_PreviousPage());
  };

  const nextPage = () => {
    dispatch(priceAssisstant_NextPage());
  };
  
  useEffect(() => {
    const zipCode = bookingContactInfo.zip;

    if (zipCode.length === 5) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }, [bookingContactInfo]);


  return (
    <div className="price_calc_footer_final">
      <PriceCalc_RectangleButton
      label="Back"
      handleClick={prevPage}
      />
      <PriceCalc_RectangleButton
      label="Next"
      handleClick={nextPage}
      enabled={validated}
      />
    </div>
  );
};

export default PriceCalc_Footer_10;
