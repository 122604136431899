import './TextArea_Input.scss'

import React, { useEffect, useRef } from 'react';

interface Props {
  input: string;
  setInput: (input: string) => void;
  placeholder?: string;
  autoFocus?: boolean;
}

const TextArea_Input: React.FC<Props> = ({
  input,
  setInput,
  placeholder = "Enter text",
  autoFocus = false,
}) => {
  const handleChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(e.target.value);
  };


  const inputRef = useRef(null);

  useEffect(() => {
    // Focus the input element when the component mounts
    if (inputRef.current && autoFocus) {
      inputRef.current.focus();
    }
  }, [autoFocus]);



  return (
    <div className="text_area_input">
      <textarea
      ref={inputRef}
        value={input}
        onChange={handleChange}
        placeholder={placeholder}
      />
    </div>
  );
};

export default TextArea_Input;
