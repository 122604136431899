/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import String_Input from "../../input-components/string-input/String_Input";
import "../PriceCalculatorWidget.scss";
import { useAppDispatch } from "../../../redux-slices/hooks";
import { setBookingContactInfo_FullName } from "../../../redux-slices/price-assistant/PriceAssistantSlice";

interface Props {}

const PriceCalc_Body_7: React.FC<Props> = ({}) => {

  const dispatch = useAppDispatch()
  const [input, setInput] = useState("")

  useEffect(()=>{
    dispatch(setBookingContactInfo_FullName(input))
  }, [input])

  return (
    <div className="price_calc_body">
      <label>What is your full name?</label>
      <String_Input
      input={input}
      setInput={setInput}
      placeholder="Mely Rodriguez"
      />
    </div>
  );
};

export default PriceCalc_Body_7;
