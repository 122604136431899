/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch } from "../../../redux-slices/hooks";
import { addDeepCleanService, priceAssisstant_NextPage } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";
import PriceCalc_RectangleButton from "./PriceCalc_RectangleButton";

interface Props {}

const PriceCalc_Footer_4a: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const acceptAddOnService = () => {
    dispatch(addDeepCleanService())
    dispatch(priceAssisstant_NextPage());
  };

  const declineAddOnService = () => {
    dispatch(priceAssisstant_NextPage());
  };

  return (
    <div className="price_calc_footer_vertical">
      <PriceCalc_RectangleButton
        label="Add Deep Cleaning"
        handleClick={acceptAddOnService}
      />
      <PriceCalc_RectangleButton
        label="Skip"
        handleClick={declineAddOnService}
      />
    </div>
  );
};

export default PriceCalc_Footer_4a;
