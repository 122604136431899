/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux-slices/hooks";
import "../PriceCalculatorWidget.scss";
import {
  priceAssisstant_NextPage,
  selectBiWeeklyDiscount,
  selectCleaningService,
  selectDeepCleaningAddOnPrice,
  selectNumberBathrooms,
  selectNumberBedrooms,
  selectServiceAddOns,
  selectServiceFrequency,
  selectWeeklyDiscount,
  selectWindowCleaningAddOnPrice,
  setPriceEstimate,
} from "../../../redux-slices/price-assistant/PriceAssistantSlice";

interface Props {}

const PriceCalc_Footer_6: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const bedrooms = useAppSelector(selectNumberBedrooms);
  const bathrooms = useAppSelector(selectNumberBathrooms);
  const serviceFrequency = useAppSelector(selectServiceFrequency);
  const cleaningService = useAppSelector(selectCleaningService);
  const serviceAddOns = useAppSelector(selectServiceAddOns);
  const weeklyDiscount = useAppSelector(selectWeeklyDiscount);
  const biWeeklyDiscount = useAppSelector(selectBiWeeklyDiscount);
  const windowCleaningAddOnPrice = useAppSelector(
    selectWindowCleaningAddOnPrice
  );
  const deepCleaningAddOnPrice = useAppSelector(selectDeepCleaningAddOnPrice);

  const [price, setPrice] = useState(150);
  const [discount, setDiscount] = useState(0);
  const [discountLabel, setDiscountLabel] = useState("");
  const priceTable = [
    [150, 170, 190, 210, 230, 250], // 1 Bed
    [170, 190, 210, 230, 250, 270], // 2 Bed
    [190, 210, 230, 250, 270, 290], // 3 Bed
    [210, 230, 250, 270, 290, 310], // 4 Bed
    [230, 250, 270, 290, 310, 330], // 5 Bed
    [250, 270, 290, 310, 330, 350], // 6 Bed
  ];
  const calculateHomeCleaningPrice = (
    bedrooms: number,
    bathrooms: number,
    discount: number
  ) => {
    if (bedrooms < 1 || bedrooms > 6 || bathrooms < 1 || bathrooms > 6) {
      throw new Error("Invalid number of bedrooms or bathrooms");
    }

    // Adjust for zero-based index
    const bedIndex = bedrooms - 1;
    const bathIndex = bathrooms - 1;

    var finalPrice = priceTable[bedIndex][bathIndex];

    finalPrice = finalPrice - discount;

    if (serviceAddOns.deepClean) {
      finalPrice = finalPrice + deepCleaningAddOnPrice;
    }
    if (serviceAddOns.windowCleaning) {
      finalPrice = finalPrice + windowCleaningAddOnPrice;
    }
    dispatch(setPriceEstimate(finalPrice));
    return finalPrice;
  };

  const calculateOfficeCleaningPrice = (
    bathrooms: number,
    discount: number
  ) => {
    const priceTable = [150, 180, 210, 240, 270, 300];  // # of Bathrooms

    var finalPrice = priceTable[bathrooms - 1];
    finalPrice = finalPrice - discount;

    if (serviceAddOns.deepClean) {
      finalPrice = finalPrice + deepCleaningAddOnPrice;
    }
    if (serviceAddOns.windowCleaning) {
      finalPrice = finalPrice + windowCleaningAddOnPrice;
    }
    dispatch(setPriceEstimate(finalPrice));
    return finalPrice;
  };

  useEffect(() => {
    if (serviceFrequency === "Every 2 weeks") {
      setDiscount(biWeeklyDiscount);
      setDiscountLabel("bi-weekly service discount applied!");
    } else if (serviceFrequency === "Every Week") {
      setDiscount(weeklyDiscount);
      setDiscountLabel("weekly service discount applied!");
    } else {
      setDiscount(0);
      setDiscountLabel("");
    }
  }, [serviceFrequency, weeklyDiscount, biWeeklyDiscount]);

  useEffect(() => {
    if (cleaningService === "Home Cleaning") {
      setPrice(calculateHomeCleaningPrice(bedrooms, bathrooms, discount));
    } else if (cleaningService === "Office Cleaning") {
      setPrice(calculateOfficeCleaningPrice(bathrooms, discount));
    }
  }, [bedrooms, bathrooms, discount, cleaningService]);

  const nextPage = () => {
    dispatch(priceAssisstant_NextPage());
  };

  return (
    <div className="price_calc_footer_final">
      <div className="price_calc_footer_final_box">
        <div className="price_calc_footer_final_box_estimate">
          <label>Price estimate:</label>
          <label>${price}</label>
        </div>
        <div className="price_calc_footer_final_box_estimate_discount">
          <label>${discount}</label>
          <label>{discountLabel}</label>
        </div>
      </div>

      <div className="price_calc_footer_final_box">
        <button className="price_calc_footer_button" onClick={nextPage}>
          <label>Book this Service!</label>
        </button>
      </div>
    </div>
  );
};

export default PriceCalc_Footer_6;
