import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux-slices/hooks";
import { selectAssistantVariant } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";

interface Props{

}

const PriceCalc_Body_0: React.FC<Props> = ({}) => {

  const assistantVariant = useAppSelector(selectAssistantVariant)

  const [bodyText, setBodyText] = useState("")

  useEffect(()=>{
    if(assistantVariant === "Booking"){
      setBodyText("Our Booking assistant will help you schedule your next service!")
    }
    else{
      setBodyText("Our Price assistant will give you a quote for your next service!")
    }

  },[assistantVariant])

  return(
    <div className={"price_calc_body"} >
        <label>{bodyText}</label>
    </div>
  )
};

export default PriceCalc_Body_0;
