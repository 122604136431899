import { useState } from 'react'
import SimpleReactValidator from 'simple-react-validator';


const ContactForm = () => {

    const [forms, setForms] = useState({
        name: '',
        email: '',
        subject: 'Home Cleaning',
        phone: '',
        message: ''
    });
    const [validator] = useState(new SimpleReactValidator({
        className: 'errorMessage'
    }));
    const changeHandler = e => {
        setForms({ ...forms, [e.target.name]: e.target.value })
        if (validator.allValid()) {
            validator.hideMessages();
        } else {
            validator.showMessages();
        }
    };

    const submitHandler = async (e) => {
        e.preventDefault();
    
        if (validator.allValid()) {
            const formData = {
                name: forms.name,
                email: forms.email,
                subject: forms.subject,
                phone: forms.phone,
                message: forms.message
            };
        
            const response = await fetch(`https://kitqwjgjcd.execute-api.us-west-2.amazonaws.com/dev/contact-form-submit`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(formData)
            });
                
            if (response.ok) {
                alert('Form submitted successfully!');
                validator.hideMessages();
                setForms({
                    name: '',
                    email: '',
                    subject: 'Home Cleaning',
                    phone: '',
                    message: ''
                });  // Clearing the form after successful submission
                console.log(response.body);
            } else {
                alert('Failed to submit the form. Please try again.');
            }
        } else {
            validator.showMessages();
        }
    };
    

    return (
        <form onSubmit={(e) => submitHandler(e)} className="contact-validation-active" >
            <div className="row">
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.name}
                            type="text"
                            name="name"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Name" />
                        {validator.message('name', forms.name, 'required|alpha_space')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.email}
                            type="email"
                            name="email"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your Email" />
                        {validator.message('email', forms.email, 'required|email')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <input
                            value={forms.phone}
                            type="phone"
                            name="phone"
                            className="form-control"
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            placeholder="Your phone" />
                        {validator.message('phone', forms.phone, 'required|phone')}
                    </div>
                </div>
                <div className="col col-lg-6 col-12">
                    <div className="form-field">
                        <select
                            onBlur={(e) => changeHandler(e)}
                            onChange={(e) => changeHandler(e)}
                            value={forms.subject}
                            type="text"
                            className="form-control"
                            name="subject">
                            <option>Home Cleaning</option>
                            <option>Office Cleaning</option>
                            <option>Window Cleaning</option>
                            <option>Move-in / Move-out Cleaning</option>
                        </select>
                        {validator.message('subject', forms.subject, 'string')}
                    </div>
                </div>
                <div className="col col-lg-12 col-12 comment-area">
                    <textarea
                        onBlur={(e) => changeHandler(e)}
                        onChange={(e) => changeHandler(e)}
                        value={forms.message}
                        type="text"
                        className="form-control"
                        name="message"
                        placeholder="Message">
                    </textarea>
                    {validator.message('message', forms.message, 'required')}
                </div>
                <div className="col col-lg-12 col-12">
                    <div className="submit-area">
                        <button type="submit" className="theme-btn">Submit Now</button>
                    </div>
                </div>
            </div>
        </form >
    )
}

export default ContactForm;