/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import { useEffect, useState } from "react";
import "../PriceCalculatorWidget.scss";
import { useAppDispatch, useAppSelector } from "../../../redux-slices/hooks";
import { selectAssistantVariant, selectCleaningService, setBookingContactInfo_Comments } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import TextArea_Input from "../../input-components/text-area-input/TextArea_Input";

interface Props {}

const PriceCalc_Body_11: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();
  const [input, setInput] = useState("");
  const [placeholder, setPlaceHolder] = useState("")
  const cleaningService = useAppSelector(selectCleaningService)


  useEffect(() => {
    dispatch(setBookingContactInfo_Comments(input));
  }, [input]);

  useEffect(()=>{
    if(cleaningService === "Home Cleaning"){
      setPlaceHolder('We have a pet dog, so kindly use pet-friendly cleaning products.')
    }else{
      setPlaceHolder("Please ensure that all the workstations are wiped down, including computer monitors and keyboards.")
    }
  }, [cleaningService])

  return (
    <div className="price_calc_body">
      <label>Would you like to add any comments or special requests?</label>
      <TextArea_Input
        input={input}
        setInput={setInput}
        placeholder={placeholder}
      />
    </div>
  );
};

export default PriceCalc_Body_11;
