import React, { Fragment } from 'react';
import Navbar from '../../components/Navbar/Navbar'
import Hero2 from '../../components/hero2/Hero2';
import ServiceSectionS2 from '../../components/ServiceSectionS2/ServiceSectionS2';
import About2 from '../../components/about2/about2';
import Testimonial2 from '../../components/Testimonial2/Testimonial2';
import CtaSection from '../../components/CtaSection/CtaSection';
import Footer from '../../components/footer/Footer';
import Scrollbar from '../../components/scrollbar/scrollbar';
import PriceCalculatorWidget from '../../components/price-calculator-widget/PriceCalculatorWidget';

const HomePage2 = () => {
    return (
        <Fragment>
            <Navbar hclass={'header-style-2'} />
            <Hero2 />
            <ServiceSectionS2 />
            <Testimonial2 />
            <PriceCalculatorWidget/>
            
            <About2 />
            {/* <ProjectSection /> */}
            

            {/* <WorkProcess /> */}

            <CtaSection />
            <Footer />
            <Scrollbar />
        </Fragment>
    )
};
export default HomePage2;