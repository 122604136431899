import largeImgHome from '../images/service-single/large-img-home.jpg'
import largeImgOffice from '../images/service-single/large-img-office.jpg'
import largeImgMoving from '../images/service-single/large-img-moving.jpg'
import largeImgWindows from '../images/service-single/large-img-windows.jpg'

import simg from '../images/service-single/img-1.jpg'



const Services = [
    {
        Id: '1',
        largeImg:largeImgHome,
        smallImg: simg,
        sTitle:'Home Cleaning',
        slug:'Home-Cleaning',
        description:'Enjoy a spotless home with our expert cleaning team. We handle all the details for a perfect clean.',
        des2:'Discover the difference with Mely Maid Service, where we provide exceptional residential cleaning tailored to your lifestyle and schedule. Our expert team uses the latest techniques and high-quality products to ensure your home is not just clean, but pristine. From deep cleaning to routine tidying, we handle it all with utmost care and professionalism. Trust us to keep your space inviting and immaculate—schedule your cleaning with us today!',
        des3:'Home cleaning description 3',
        bullet1: "General Cleaning",
        bullet2: "Deep Cleaning",
        bullet3: "Custom Cleaning Services",
        icon:'noto:house',
    },
    {
        Id: '2',
        largeImg:largeImgOffice,
        smallImg: simg,
        sTitle:'Office Cleaning',
        slug:'Office-Cleaning',
        description:'Get consistent, high-quality office cleaning. Our team provides reliable service to keep your space professional.',
        des2:'Elevate your workplace with Mely Maid Service’s professional office cleaning. Our skilled team delivers a comprehensive cleaning solution, ensuring every workspace is meticulously organized and sanitized for peak productivity and a healthier environment. We adapt our services to fit your business schedule and cleaning requirements, offering everything from daily tidying to specialized deep cleaning. Trust us to maintain your office at the highest standards of cleanliness—contact us now to arrange your service!',
        des3:'Office cleaning description 3',
        bullet1: "General Cleaning",
        bullet2: "Deep Cleaning",
        bullet3: "Custom Cleaning Services",
        icon:'noto:file-cabinet',
    },
    {
        Id: '3',
        largeImg:largeImgWindows,
        smallImg: simg,
        sTitle: 'Window Cleaning',
        slug:'Window-Cleaning',
        description:'Keep your windows crystal clear. Our experts provide high-quality cleaning for bright and beautiful views.',
        des2:"Experience crystal-clear views with Mely Maid Service's window cleaning. Our team specializes in accessible window cleaning, ensuring every glass surface within reach sparkles without the need for special equipment. We take care to remove dirt, smudges, and streaks, enhancing the natural light in your space. Contact us to schedule a service that brightens your home or office with beautifully clean windows.",
        des3:'Window cleaning description 3',
        bullet1: "General Cleaning",
        bullet2: "Deep Cleaning",
        bullet3: "Custom Cleaning Services",
        icon:'noto:window',
    },
    {
        Id: '4',
        largeImg:largeImgMoving,
        smallImg: simg,
        sTitle: 'Move-In / Move-Out Cleaning',
        slug:'move-in-cleaning',
        description:'Moving? Let us handle the cleaning! Our services guarantee a clean slate on arrival or a spotless handover.',
        des2:"Make your moving experience smoother with Mely Maid Service's move-in / move-out cleaning. Our detailed approach ensures every inch of your space is spotless, from scrubbing floors to cleaning cupboards and sanitizing bathrooms. Whether you're handing over the keys or settling into a new home, our professional cleaning eases transitions and leaves homes immaculate. Contact us to schedule a comprehensive clean that helps you move stress-free.",
        des3:'Mely Maid Service offers Move-in/Move-out cleaning service for either residential or commercial. Move-in/Move-out cleaning service includes all of the services listed under our regular house cleaning service but also includes refrigerator in/out, oven in/out, and all cabinets in/out cleaning.',
        bullet1: "General Cleaning",
        bullet2: "Deep Cleaning",
        bullet3: "Custom Cleaning Services",
        icon:'noto:package',
    }
]    

export default Services;