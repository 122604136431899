/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch, useAppSelector } from "../../../redux-slices/hooks";
import "../PriceCalculatorWidget.scss";
import PriceCalc_RectangleButton from "./PriceCalc_RectangleButton";
import { priceAssisstant_NextPage, priceAssisstant_PreviousPage, selectBookingContactInfo } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import { useEffect, useState } from "react";

interface Props {}

const PriceCalc_Footer_8: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const bookingContactInfo = useAppSelector(selectBookingContactInfo)
  const [validated, setValidated] = useState(false);

  
  const prevPage = () => {
    dispatch(priceAssisstant_PreviousPage());
  };

  const nextPage = () => {
    dispatch(priceAssisstant_NextPage());
  };

  useEffect(() => {
    const email = bookingContactInfo.email;
    // Regular expression for validating an email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (emailRegex.test(email)) {
      setValidated(true);
    } else {
      setValidated(false);
    }
  }, [bookingContactInfo]);



  return (
    <div className="price_calc_footer_final">
      <PriceCalc_RectangleButton
      label="Back"
      handleClick={prevPage}
      />
      <PriceCalc_RectangleButton
      label="Next"
      handleClick={nextPage}
      enabled={validated}
      />
    </div>
  );
};


export default PriceCalc_Footer_8;
