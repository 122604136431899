/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import "../PriceCalculatorWidget.scss";

interface Props {}

const PriceCalc_Body_12: React.FC<Props> = ({}) => {
  return (
    <div className="price_calc_body_submitted">
      <label>Awesome! We have received your booking request!</label>
      <label>
        One of our team members will reach out to you within the next business
        day.
      </label>
      <label>
        They will assist you with scheduling, service details, and finalizing
        the quote.
      </label>
      <label>
        Thank you for choosing Mely Maid Service!
      </label>
      
    </div>
  );
};

export default PriceCalc_Body_12;
