import { useAppSelector } from "../../../redux-slices/hooks";
import {
  selectCleaningService,
  selectNumberBathrooms,
  selectNumberBedrooms,
  selectServiceAddOns,
  selectServiceFrequency,
} from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import IconWithLabel from "../../icon-with-label/IconWithLabel";
import "../PriceCalculatorWidget.scss";

interface Props {}

const PriceCalc_Body_6: React.FC<Props> = ({}) => {
  const cleaningService = useAppSelector(selectCleaningService);
  const ServiceFrequency = useAppSelector(selectServiceFrequency);
  const numBedrooms = useAppSelector(selectNumberBedrooms);
  const numBathrooms = useAppSelector(selectNumberBathrooms);
  const serviceAddOns = useAppSelector(selectServiceAddOns);

  return (
    <div className="price_calc_body">
      <div className={"price_calc_body_final_top"}>
        <IconWithLabel
          icon={
            cleaningService === "Home Cleaning"
              ? "noto:house"
              : "noto:office-building"
          }
          label={cleaningService}
        />

        <IconWithLabel icon="noto:spiral-calendar" label={ServiceFrequency} />

        {numBedrooms > 0 && (
          <IconWithLabel
            icon="noto:bed"
            label={numBedrooms > 1 ? `${numBedrooms} Bedrooms` : "1 Bedroom"}
          />
        )}

        {numBathrooms > 0 && (
          <IconWithLabel
            icon={
              cleaningService === "Home Cleaning"
                ? "noto:bathtub"
                : "noto:restroom"
            }
            label={
              numBathrooms > 1 ? `${numBathrooms} Bathrooms` : "1 Bathroom"
            }
          />
        )}

        {serviceAddOns.deepClean && (
          <IconWithLabel icon="noto:sponge" label={`+Deep Clean`} />
        )}
        {serviceAddOns.windowCleaning && (
          <IconWithLabel icon="noto:window" label={`+Window Cleaning`} />
        )}
      </div>
      <div className={"price_calc_body_final_bottom"}>
        <label>
          Your tailored estimate is ready, and we can't wait to brighten up your
          home!
        </label>
        <label>Please note that this is only an estimate</label>
      </div>
    </div>
  );
};

export default PriceCalc_Body_6;
