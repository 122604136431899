import { useAppDispatch } from "../../../redux-slices/hooks";
import { priceAssisstant_NextPage } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";

interface Props {}

const PriceCalc_Footer_0: React.FC<Props> = ({}) => {
  const dispatch = useAppDispatch();

  const handleButtonClick = () => {
    dispatch(priceAssisstant_NextPage());
  };

  return (
    <div className={"price_calc_footer"}>
      <button
        onClick={() => {
          handleButtonClick();
        }}
        className="price_calc_footer_button"
      >
        Let's get started!
      </button>
    </div>
  );
};

export default PriceCalc_Footer_0;
