import React from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import "./FaqSection.scss"

const FaqSection = (props) => {

    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };


    return (
        <div className="wpo-benefits-section">
            <div className="row">
                <div className="col-lg-12 col-12">
                    <div className="faq-accordion theme-accordion-s1">
                        <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Typography
                                className='accordian-summary'
                                >Does Mely Maid supply their own cleaning products?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Yes, we bring our own cleaning products. You do not need to supply us with anything for the job.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                            >
                                <Typography
                                className='accordian-summary'
                                >Are the interiors of the fridge and oven included in the regular house cleaning service?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography
                                
                                >
                                The insides of the fridge are included in our Deep Cleaning service.
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel3bh-content"
                                id="panel3bh-header"
                            >
                                <Typography
                                                                className='accordian-summary'

                                >Are the cleaning of baseboards included with the general house cleaning service?</Typography>
                
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Yes, the cleaning of baseboards is included with the general house cleaning service
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel4'} onChange={handleChange('panel4')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel4bh-content"
                                id="panel4bh-header"
                            >
                                <Typography
                                className='accordian-summary'
                                > Do you charge extra for cleaning the laundry room?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                We do not charge extra. Cleaning the laundry room is included with the general house cleaning service. (Sweep and mop floors)
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                        <Accordion expanded={expanded === 'panel5'} onChange={handleChange('panel5')}>
                            <AccordionSummary
                                expandIcon={""}
                                aria-controls="panel4bh-content"
                                id="panel5bh-header"
                            >
                                <Typography
                                className='accordian-summary'
                                > What are your office hours?</Typography>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Typography>
                                Office Hours: 8:00AM - 5:00PM PST
                                </Typography>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FaqSection;