import React from 'react'
import abimg from '../../images/orange-county-logo.jpg'


const About = (props) => {
    return (
        <section className={`about-section section-padding ${props.abClass}`}>
            <div className="container">
                <div className="row">
                    <div className="col col-lg-6 col-12">
                        <div className="section-title-s2">
                            <span>About our history</span>
                            <h2>Serving Orange County Since 2002!</h2>
                        </div>
                        <div className="details">
                            <p>
                            Welcome to Mely Maid Service, where over 20 years of experience means your home will be spotlessly clean and cozy. We adjust our cleaning to fit the unique needs of busy professionals, families, and homeowners. Known for our dependable and thorough service, our experienced team ensures great results every time. Contact us today to see how our long-standing commitment to quality can refresh your living space!
                            </p>
                            <ul>
                                <li><i className="ti-check-box"></i>Experienced and Reliable: Over 20 years in the business, ensuring top-notch cleaning and customer satisfaction.</li>
                                <li><i className="ti-check-box"></i>Customized Cleaning Solutions: Services tailored to meet the specific needs of each customer, whether for routine maintenance or deep cleaning.</li>
                                <li><i className="ti-check-box"></i>Professional Team: Skilled and trustworthy professionals dedicated to delivering efficient and impeccable cleaning results.</li>
                            </ul>
                            <h4>I highly recommend Mely Maid Cleaning Service. Mely Maid has cleaned my home for over 20 years and are the most efficient and innovative cleaning service I have ever seen.</h4>
                            <div className="quoter">
                                - Christine M. Fabiani, <span>Knots of Love executive Director</span>
                            </div>
                        </div>
                    </div>
                    <div className="col col-lg-6 col-12">
                        <div className="img-video-holder">
                            <img src={abimg} alt="" />
                            <div className="video-holder">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;