/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch } from "../../../redux-slices/hooks";
import { addWindowCleaningService, priceAssisstant_NextPage } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";
import PriceCalc_RectangleButton from "./PriceCalc_RectangleButton";

interface Props {}

const PriceCalc_Footer_4b: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const acceptAddOnService = () => {
    dispatch(addWindowCleaningService())
    dispatch(priceAssisstant_NextPage());
  };

  const declineAddOnService = () => {
    dispatch(priceAssisstant_NextPage());
  };

  return (
    <div className="price_calc_footer_vertical">
      <PriceCalc_RectangleButton
        label="Add Window Cleaning"
        handleClick={acceptAddOnService}
      />
      <PriceCalc_RectangleButton
        label="Skip"
        handleClick={declineAddOnService}
      />
    </div>
  );
};

export default PriceCalc_Footer_4b;
