/* eslint-disable react/jsx-pascal-case */
import "../PriceCalculatorWidget.scss";

interface Props {}

const PriceCalc_Footer_12: React.FC<Props> = () => {


  return (
    <div className="price_calc_footer_final">

    </div>
  );
};

export default PriceCalc_Footer_12;
