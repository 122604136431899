/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch } from "../../../redux-slices/hooks";
import {
  priceAssisstant_NextPage,
  setServiceFrequency,
} from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";
import PriceCalc_RectangleButton from "./PriceCalc_RectangleButton";
import { ServiceFrequencyType } from "../../../redux-slices/price-assistant/ServiceFrequencyType";

interface Props {}

const PriceCalc_Footer_4c: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const handleButtonClick = (i: ServiceFrequencyType) => {
    dispatch(priceAssisstant_NextPage());
    dispatch(setServiceFrequency(i));
  };

  const serviceFrequencies: ServiceFrequencyType[] = [
    "One-time Service",
    "Every 2 weeks",
    "Every Week",
    "Every Month",
  ];

  return (
    <div className="price_calc_footer_grid_4">
      {serviceFrequencies.map((i) => (
        <div key={i} className="price_calc_footer_grid_square">
          <PriceCalc_RectangleButton
            label={i}
            handleClick={() => {
              handleButtonClick(i);
            }}
          />
        </div>
      ))}
    </div>
  );
};

export default PriceCalc_Footer_4c;
