import React from 'react'
import { Link } from 'react-router-dom'


const HeaderTopbar = () => {
    return (
        <div className="topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-md-3 col-12">
                        <div className="social">
                            <ul>
                                {/* <li><Link to="/"><i className="ti-facebook"></i></Link></li>
                                <li><Link to="/"><i className="ti-twitter-alt"></i></Link></li>
                                <li><Link to="/"><i className="ti-linkedin"></i></Link></li>
                                <li><Link to="/"><i className="ti-pinterest"></i></Link></li>
                                <li><Link to="/"><i className="ti-vimeo-alt"></i></Link></li> */}
                            </ul>
                        </div>
                    </div>
                    <div className="col col-md-9 col-12">
                        <div className="text">
                            <p>Interested in our cleaning services? 
                                <br/> Call us at 
                                <a 
                                className='text_phone_number'
                                href="tel:+19496499250">949-649-9250</a>
                                </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default HeaderTopbar;