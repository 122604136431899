import "../PriceCalculatorWidget.scss";

interface Props {
  label: string;
  handleClick: () => void;
  enabled?: boolean;
  type?: "button" | "submit";
}

const PriceCalc_RectangleButton: React.FC<Props> = ({
  label,
  handleClick,
  enabled = true,
  type = "button",
}) => {
  return (
    <button
      type={type}
      className={
        enabled && type === "submit"
          ? "price_calc_footer_rectangle_button_submit"
          : enabled && type === "button"
          ? "price_calc_footer_rectangle_button" :
          type === "submit" ? 
          "price_calc_footer_rectangle_button_submit_disabled" 
          : "price_calc_footer_rectangle_button_disabled"
      }
      onClick={handleClick}
      disabled={!enabled}
    >
      <label>{label}</label>
    </button>
  );
};

export default PriceCalc_RectangleButton;
