import React from "react";
import { Navigation, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import { Link } from 'react-router-dom'
import hero1 from '../../images/slider/slide-1.jpg'
import hero2 from '../../images/slider/slide-2.jpg'


const Hero2 = () => {
    return (

        <section className="hero-slider hero-style-2">
           <div className="slide-inner slide-bg-image" style={{ backgroundImage: `url(${hero1})` }}>
                        <div className="container">
                            <div className="slide-offer">
                                <span>10% off your first service!</span>
                            </div>
                            <div className="slide-title">
                                <h2>Mely Maid Service</h2>
                            </div>
                            <div className="slide-text">
                                <p>The best home and office cleaning service in Orange County!</p>
                            </div>
                            <div className="clearfix"></div>
                            <div data-swiper-parallax="500" className="slide-btns">
                                <Link to="/pricing" className="theme-btn-s2">Get a Quote<span></span></Link>
                                <Link to="/book-now" className="theme-btn-s3">Book a Service<span></span></Link>
                            </div>
                        </div>
                    </div>
        </section>
    )
}

export default Hero2;