import pimg from '../images/latest-projects/img-1.jpg'
import pimg2 from '../images/latest-projects/img-2.jpg'
import pimg3 from '../images/latest-projects/img-3.jpg'
import pimg4 from '../images/latest-projects/img-4.jpg'
import pimg5 from '../images/latest-projects/img-5.jpg'
import pimg6 from '../images/latest-projects/img-6.jpg'
import pimg7 from '../images/latest-projects/img-7.jpg'


const Projects = [
    {
        Id: '1',
        pImg:pimg,
        subTitle:'House Cleaning Service',
        title: 'Bedroom',
        slug:'Home-Cleaning',
        description:"Our living room cleaning service meticulously covers dusting and polishing all surfaces, vacuuming carpets, and cleaning upholstery, ensuring every corner is spotless. We also organize items and remove clutter, leaving your space fresh and inviting.",
    },
    {
        Id: '2',
        pImg:pimg2,
        subTitle:'House Cleaning Service',
        title: 'Living Room',
        slug:'Home-Cleaning',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '3',
        pImg:pimg3,
        subTitle:'House Cleaning Service',
        title: 'Kitchen',
        slug:'Home-Cleaning',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '4',
        pImg:pimg4,
        subTitle:'House Cleaning Service',
        title: 'Bathroom',
        slug:'Home-Cleaning',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '5',
        pImg:pimg5,
        subTitle:'Cleaning Service',
        title: 'Move-In / Move-Out',
        slug:'move-in-cleaning',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '6',
        pImg:pimg6,
        subTitle:'Cleaning Service',
        title: 'Window Cleaning',
        slug:'Window-Cleaning',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
    {
        Id: '7',
        pImg:pimg7,
        subTitle:'Cleaning Service',
        title: 'Office Cleaning',
        slug:'Office-Cleaning',
        description:'We are certified company. We provide best cleaning projects for you and your company Home projects and your Car.',
    },
]
export default Projects;