/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch, useAppSelector } from "../../../redux-slices/hooks";
import "../PriceCalculatorWidget.scss";
import PriceCalc_RectangleButton from "./PriceCalc_RectangleButton";
import { priceAssisstant_NextPage, selectBookingContactInfo } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import { useEffect, useState } from "react";

interface Props {}

const PriceCalc_Footer_7: React.FC<Props> = () => {
  const dispatch = useAppDispatch();

  const bookingContactInfo = useAppSelector(selectBookingContactInfo)
  const [validated, setValidated] = useState(false);

  const nextPage = () => {
    dispatch(priceAssisstant_NextPage());
  };

  useEffect(()=>{
    const fullName = bookingContactInfo.fullName
    if(fullName.length > 3){
      setValidated(true)
    }
    else{
      setValidated(false)
    }
  }, [bookingContactInfo])

  return (
    <div className="price_calc_footer_final">
      <PriceCalc_RectangleButton 
      label="Next" 
      handleClick={nextPage}
      enabled={validated}
      />
    </div>
  );
};

export default PriceCalc_Footer_7;
