import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import MobileMenu from '../MobileMenu/MobileMenu'
import HeaderTopbar from '../HeaderTopbar/HeaderTopbar';
import Logo from '../../images/logo.png'

const Header = (props) => {
    const [menuActive, setMenuState] = useState(false);

    const SubmitHandler = (e) => {
        e.preventDefault()
        alert('Failed to submit form. Please try again later');

    }

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    return (
        <header id="header" className={`site-header ${props.hclass}`}>
            <HeaderTopbar />
            <nav className="navigation navbar navbar-expand-lg navbar-light">
                <div className="container">
                    <div className="navbar-header">
                        <MobileMenu />
                        <Link onClick={ClickHandler} className="navbar-brand" to="/home"><label className='navbar-sitename'>Mely Maid Service</label></Link>
                    </div>
                    <div id="navbar" className="collapse navbar-collapse navigation-holder">
                        <ul className="nav navbar-nav">
                            <li><Link onClick={ClickHandler} to="/">Home</Link></li>

                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/about">About</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/about">About Us</Link></li>
                                    <li><Link onClick={ClickHandler} to="/testimonials">Testimonials</Link></li>
                                    <li><Link onClick={ClickHandler} to="/faq">FAQs</Link></li>
                                </ul>
                            </li>

                            <li className="menu-item-has-children">
                                <Link onClick={ClickHandler} to="/services">Services</Link>
                                <ul className="sub-menu">
                                    <li><Link onClick={ClickHandler} to="/services">About Our Services</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/Home-Cleaning">Home Cleaning</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/Office-Cleaning">Office Cleaning</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/Window-Cleaning">Window Cleaning</Link></li>
                                    <li><Link onClick={ClickHandler} to="/service-single/move-in-cleaning">Move-In/Move-Out Cleaning</Link></li>

                                </ul>
                            </li>

                            <li><Link onClick={ClickHandler} to="/pricing">Pricing</Link></li>


                            <li><Link onClick={ClickHandler} to="/contact">Contact</Link></li>
                        </ul>
                    </div>

                    <div className="search-contact">
                        <div className="header-search-area">
                            <div className={`header-search-form ${menuActive ? "header-search-content-toggle" : ""}`}>
                                <div className="close-btn" onClick={() => setMenuState(!menuActive)}><i className='ti-close'></i></div>
                                <form onSubmit={SubmitHandler} className="form">
                                    <div>
                                        <input type="text" className="form-control"
                                            placeholder="Search here..." />
                                        <button type="submit" className="btn"><i
                                            className="ti-search"></i></button>
                                    </div>
                                </form>
                            </div>

                        </div>
                        <div className="contact">
                            <Link onClick={ClickHandler} to="/book-now" className="theme-btn">Book Now</Link>
                        </div>
                    </div>
                </div>
            </nav>
        </header>

    )
}

export default Header;