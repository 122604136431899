/* eslint-disable react/jsx-pascal-case */
import { useAppDispatch } from "../../../redux-slices/hooks";
import { priceAssisstant_NextPage } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";
import { useEffect, useState } from "react";

interface Props {}

const PriceCalc_Footer_5: React.FC<Props> = () => {
  const dispatch = useAppDispatch();
  const [percentFill, setPercentFill] = useState(0);

  //used for debugging purposes
  const [restartFill, setRestartFill] = useState(false);

  // Decorative loading bar for user delight
  useEffect(() => {
    setPercentFill(0);
    const interval = setInterval(() => {
      setPercentFill((prev) => {
        if (prev >= 100) {
          clearInterval(interval);
          setTimeout(() => {
            nextPage();
          }, 1400); // Pause for 1 second before calling nextPage
          return 100;
        }
        return prev + 1;
      });
    }, 36); // 5000ms / 100 = 50ms per percent

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [restartFill]);

  const nextPage = () => {
    dispatch(priceAssisstant_NextPage());
  };

  return (
    <div className="price_calc_footer">
      <div className="price_calc_loading_bar">
        <label className="price_calc_loading_bar_label">{percentFill}%</label>
        <div
          style={{ width: `${percentFill}%` }}
          className="price_calc_loading_bar_inner"
          onClick={() => {
            setRestartFill(!restartFill);
          }}
        />
      </div>
    </div>
  );
};

export default PriceCalc_Footer_5;
