import { useEffect, useState } from "react";
import { useAppSelector } from "../../../redux-slices/hooks";
import { selectCleaningService } from "../../../redux-slices/price-assistant/PriceAssistantSlice";
import "../PriceCalculatorWidget.scss";

interface Props {}

const PriceCalc_Body_4c: React.FC<Props> = ({}) => {
  const cleaingService = useAppSelector(selectCleaningService);
  const [serviceString, setServiceString] = useState("home");

  useEffect(() => {
    if (cleaingService === "Home Cleaning") {
      setServiceString("home");
    } else {
      setServiceString("office");
    }
  }, [cleaingService]);

  return (
    <div className={"price_calc_body"}>
      <label>How often would you like your {serviceString} cleaned?</label>
      <label>(We provide discounts for weekly and bi-weekly services!)</label>
    </div>
  );
};

export default PriceCalc_Body_4c;
